import cover from "../../assets/images/home/_00-Home-Hero video.mp4"
import work1 from "../../assets/images/home/workAtTier/1.png"
import work2 from "../../assets/images/home/workAtTier/2.png"
import work3 from "../../assets/images/home/workAtTier/3.png"
import work4 from "../../assets/images/home/workAtTier/4.png"
import howto from "../../assets/images/home/howToRideTier/01_howToRideTier_desktop.png"
import plCta1 from "../../assets/images/home/parallax/miles_smiles/CloudBottomRight.svg"
import plCta2 from "../../assets/images/home/parallax/miles_smiles/CloudMiddle.svg"
import plCta3 from "../../assets/images/home/parallax/miles_smiles/CloudMiddleRight.svg"
import plCta4 from "../../assets/images/home/parallax/miles_smiles/CloudUpLeft.svg"
import plCta5 from "../../assets/images/home/parallax/miles_smiles/CloudUpRight.svg"
import plTi1 from "../../assets/images/home/parallax/climate_neutral/HillGreen.svg"
import plTi2 from "../../assets/images/home/parallax/climate_neutral/BushesLeft.svg"
import plTi3 from "../../assets/images/home/parallax/climate_neutral/Cloud.svg"
import plTi4 from "../../assets/images/home/parallax/climate_neutral/TreeLeft.svg"
import plTi5 from "../../assets/images/home/parallax/climate_neutral/BushesRight.svg"
import plTi6 from "../../assets/images/home/parallax/climate_neutral/HillWhite.svg"
import plTi7 from "../../assets/images/home/parallax/climate_neutral/TreesRight.svg"
import smilesIcon from "../../assets/images/home/_02_Miles-Smiles.svg"
import businessIcon from "../../assets/images/home/_02_Climate-Neutral.svg"
import workIcon from "../../assets/images/home/corporate-mobility.png"

export const homeContent = {
  seo: {
    metaTitle: "metaTitleHome",
    metaDescription: "metaDescriptionHome",
  },
  mainHero: {
    headline: "homeMainHeroHeadline",
    subline: "homeMainHeroSubline",
    type: "fullscreen",
    media: {
      type: "video",
      link: cover,
    },
  },
  textCta: {
    headline: "homeTextCtaHeadline",
    description: "homeTextCtaDescription",
    buttonText: "homeTextCtaButton",
    buttonLink: {
      link: "/business",
      internal: true,
    },
    backgroundColor: "green-background",
    textColor: "blue",
    parallax: {
      layers: [plCta1, plCta2, plCta3, plCta4, plCta5],
      factor: 2,
    },
    iconPosition: "left",
    icon: smilesIcon,
    alt: "tier-urban-transport-icon",
  },
  textIcon: {
    headline: "homeTextIconHeadline",
    description: "homeTextIconDescription",
    buttonText: "homeTextIconButton",
    buttonLink: {
      link: "https://about.tier.app/sustainability/",
      force: true,
    },
    backgroundColor: "green-background",
    align: "items-start",
    parallax: {
      layers: [plTi1, plTi2, plTi3, plTi4, plTi5, plTi6, plTi7],
      factor: 14,
    },
    textColor: "blue",
    iconPosition: "right",
    icon: businessIcon,
    alt: "tier-climate-neutral-icon",
  },
  textImage: {
    headline: "homeTextImageHeadline",
    description: "homeTextImageDescription",
    media: [
      { type: "image", link: work1, alt: "tier-battery-exchange" },
      { type: "image", link: work2, alt: "tier-employee-meeting" },
      { type: "image", link: work3, alt: "tier-employee-work" },
      { type: "image", link: work4, alt: "tier-man-safety-vest" },
    ],
    type: "normal",
    buttons: [
      {
        text: "homeTextImageButtonOne",
        link: {
          link: "https://about.tier.app/jobs",
          force: true,
        },
        color: "primary",
        variant: "filled",
      },
    ],
  },
  textImageTwo: {
    headline: "homeTextImageTwoHeadline",
    description: "homeTextImageTwoDescription",
    media: { type: "image", link: howto, fit: true, alt: "tier-products" },
    type: "reversed",
    buttons: [
      {
        text: "homeTextImageTwoButton",
        link: {
          link: "/how-tier-works",
          internal: true,
        },
        color: "primary",
        variant: "filled",
      },
    ],
  },
  textIconTwo: {
    headline: "homeTextIconTwoHeadline",
    description: "homeTextIconTwoDescription",
    buttonText: "tierBusinessCta",
    buttonLink: {
      link: "/business",
      internal: true,
    },
    backgroundColor: "blue-background",
    textColor: "blue",
    iconPosition: "left",
    icon: workIcon,
    alt: "tier-corporate-mobility-icon",
  },
  newsletterForm: {
    headline: "homeNewsletterFormHeadline",
    consentText: "homeNewsletterFormConsent",
    buttonText: "homeNewsletterFormButton",
  },
}
