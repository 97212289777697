import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

// Components
import SEO from "../components/SEO"
import HeroMedia from "../modules/HeroMedia/HeroMedia"
import TextIcon from "../modules/TextIcon/TextIcon"
import TextImage from "../modules/TextImage/TextImage"
// import NewsletterForm from "../modules/NewsletterForm/NewsletterForm"

// Helpers & mock content
import { fireEvent } from "../utils/helpers"
import { homeContent } from "../utils/content/homeContent"

// markup
const IndexPage = () => {
  const { formatMessage } = useIntl()
  const { seo, mainHero, textImage, textCta, textIcon, textImageTwo, textIconTwo } = homeContent

  useEffect(() => {
    fireEvent("nav-scheme", "light")
  }, [])

  return (
    <div className="relative overflow-hidden">
      <SEO title={formatMessage({ id: seo.metaTitle })} description={formatMessage({ id: seo.metaDescription })} />
      <HeroMedia content={mainHero} />
      <TextIcon content={textCta} />
      <TextIcon content={textIcon} />
      <TextImage content={textImage} />
      <TextImage content={textImageTwo} />
      <TextIcon content={textIconTwo} />
      {/* <NewsletterForm content={newsletterForm} /> */}
    </div>
  )
}

export default IndexPage
